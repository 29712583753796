import React from 'react';
import { formatAsCurrency } from '../../util';
import { calculateCartTotalWithCoupon } from './EventSectionsCheckout';
import {
  calculateCartTotal,
  EventSectionsCartCouponUseModel,
  EventSectionsCartModel
} from './EventSectionsForm';
import classNames from 'classnames';

export const CartCouponInfo = ({
  couponUse,
  cart,
  removeBtn,
  className = ''
}: {
  couponUse: EventSectionsCartCouponUseModel;
  cart: EventSectionsCartModel;
  removeBtn?: React.ReactNode;
  className?: string;
}) => {
  if (!couponUse?.coupon) {
    return <></>;
  }
  return (
    <div className={classNames({ 'font-semibold': true }, className)}>
      <div className="text-primary-dark flex gap-4">
        <span>Coupon applied: {couponUse?.coupon?.code}</span>
        {removeBtn}
      </div>
      <div className="text-primary-dark">
        Discount:{' '}
        {couponUse?.coupon?.type === 'FIXED'
          ? formatAsCurrency(
              Math.min(
                calculateCartTotal(cart),
                couponUse.coupon.discountAmount || 0
              ) / 100
            )
          : `${couponUse?.coupon?.discountPercentage}%`}
      </div>

      <div className="text-primary-dark">
        Total after discount:{' '}
        {formatAsCurrency(calculateCartTotalWithCoupon(cart, couponUse) / 100)}
      </div>
    </div>
  );
};
